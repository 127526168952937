import React from "react"; 
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import ImageTable from "./ImageTable.js";
import TopImageTable from "./TopImageTable.js";

const Covid = () => {
    return(
        <div id="everything">
            <NavigationBar />
            <img src="./images/20210513_133802684_iOS_Compressed.png" alt="Angela showing the API Lab COVID T-shirt" />
            <div className='text-center text-black justify-center py-4 text-xl md:text-5xl lg: text-6xl xl:text-7xl'>
                COVID-19 Research in the APILab
            </div>
            <TopImageTable />
            <div className='text-left text-black justify-left py-2 mx-8 text-base md:text-lg lg:text-xl xl:text-3xl'>
                COVID-19 infection can result in dramatic and damaging effects to the respiratory system, including:
            </div>
            <div className='text-left text-black justify-left py-2 mx-14 text-base md:text-lg lg:text-xl xl:text-3xl'>
                <li>Viral pneumonia affecting terminal airways and alveoli</li>
                <li>Destruction of crucial alveolar cells</li>
                <li>Whole-lung inflammation</li>
                <li>Failed gas exchange</li>
                <li>Blood clotting disorders</li>
                <li>Total respiratory failure</li>
            </div>
            <div className='text-left text-black justify-left mx-8 py-2 text-base md:text-lg lg:text-xl xl:text-3xl'>
                In survivors of severe disease, CT imaging shows strong evidence of persistent abnormalities and damage even after recovery, which may have long-term consequenses both for survivors themselves and for the health-care system. Sensitive and
                specific predictors or biomarkers that can evaluate quality-of-life, risk of post-COVID-19 infection exacerbation (including shortness of breath, lung infection) and other long-term outcomes are critically needed. To better plan healthcare
                and societal resources, it is also crucial to understand post-infection findings in everyone, including those with pre-existing chronic lung diseases like asthma and COPD.
            </div>
            <div className='text-left text-black justify-left mx-8 py-2 text-base md:text-lg lg:text-xl xl:text-3xl'>
                Non-invasive imaging using hyperpolarized xenon-129 and low dose CT provides a way to comprehensively detect abnormalities in small airways, vasculature, and parenchyma. Xenon-129 MRI is a fast (10 second), non-invasive, and radiation-free
                method to measure the structure and function of the terminal airways where gas exchange happens. This approach provides a way to simultaneously quantify the regions of the lung where ventillation and perfusion happen, and where it
                does not.
            </div>
            <div className='text-left text-black justify-left mx-8 text-base md:text-lg lg:text-xl xl:text-3xl'>
                We are using CT and <sup>129</sup>Xe MRI with survivors of mild and severe COVID-19 infection to answer the following questions: 
            </div>
            <div className='text-left text-black justify-left py-2 mx-14 text-base md:text-lg lg:text-xl xl:text-3xl'>
                1. What lasting effects persist in COVID-19 patients after recovery?
                <br></br>
                2. Why do men suffer severe disease and death at a much greater rate than women?
                <br></br>
                3. Are there differences in pulmonary ventilation or vascular abnormalities related to previous ICU or outpatient treatment?
                <br></br>
                4. Can MRI and CT biomarkers explain persistent symptoms and help guide future treatment decisions to ultimately minimize severe disease and health care utilization?
                <br></br>
                5. Do patients with chronic lung disease, including asthma and COPD, need different strategies to improve outcomes?
            </div>
            <ImageTable />
            <Footer />
        </div>
    )
}

export default Covid