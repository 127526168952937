import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

const Patients = () => {
    return (
        <div id="everything">
            <NavigationBar />
            <img src="./images/paintingPicture patients.png" alt="Participant in the MRI" />
            <br></br>
            <div className='text-center text-black justify-center text-xl md:text-5xl lg: text-6xl xl:text-7xl'>
                Join a Study
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-6 text-base md:text-lg lg:text-xl xl:text-4xl'>
                If you have been diagnosed with Asthma, have a history of vaping or cannabis use, or have 
                no history of respiratory disease, we are currently performing research studies that you
                might be interested in as outlined below:
            </div>
            <br></br>
            <div className="box">
                <div className='text-center text-black justify-left text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                    Healthy Volunteers:
                </div>
                <a href="./files/Healthy Volunteers Letter of Information V15.pdf" target="blank">
                    <p className = "button">Letter of Information</p>
                </a>
            </div>
            <div className="box">
                <div className='text-center text-black justify-left text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                    Asthma:
                </div>
                <a href="./files/Asthma Letter of Information V13.pdf" target="blank">
                    <p className = "button">Letter of Information</p>
                </a>
            </div>
            <div className="box">
                <div className='text-center text-black justify-left text-lg md:text-xl lg:text-2xl xl:text-4xl'>
                    Vaping:
                </div>
                <a href="./files/Vaping Letter of Information and Consent V5 Oct 24 2023.pdf" target="blank">
                    <p className = "button">Letter of Information</p>
                </a>
            </div>
            <div className='text-left text-black justify-left mx-6 text-base md:text-lg lg:text-xl xl:text-4xl'p>
                Please feel free to read the letters of information linked above and if you are interested 
                more information or participating, email awilson@robarts.ca or call Angela at 519-931-5777 
                Ext. 24197 for more information.
            </div>
            <br></br>
            <Footer />
        </div>
    )
};

export default Patients