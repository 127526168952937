import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

const Polarization = () => {
    return (
        <div id="everything">
            <NavigationBar />
            <div className='text-center text-black justify-center py-2 text-xl md:text-5xl lg: text-6xl xl:text-7xl'>
                Polarization Facility
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
                At Robarts Research Institute, one of the key features is the polarization facility 
                that enables our research in lung diseases focusing on the development and validation 
                of biomarkers to provide a better understanding of structural and functional information.
            </div>
            <br></br>
            <div className='text-left text-black font-bold justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
                Layout of our facility at Robarts Research Institute
            </div>
            <div className="video-container">
                <video controls style={{width:"95%"}}>
                    <source src="./files/Lab Walkthrough May 27th.mp4" />
                </video>
            </div>
            <br></br>
            <div className='text-left text-black font-bold justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
                How hyperpolarized gas is collected and administered to participants
            </div>
            <div className="video-container">
                <video controls style={{width:"95%"}}>
                    <source src="./files/Administering Contrast.mp4" />
                </video>
            </div>
            <br></br>
            <Footer />
        </div>
    );
}

export default Polarization;