import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

const Volunteering = () => {
    return(
        <div id="everything">
            <NavigationBar />
            <img src="./images/RMcDonaldJul2022_1.jpg" alt="The team at the Ronald McDonald House" />
            <div className='text-center text-black justify-center text-xl md:text-5xl lg: text-6xl xl:text-7xl pt-4 pb-4'>
                Ronald McDonald House
            </div>
            <div className="text-center justify-center text-black text-base md:text-lg lg: text-xl xl:text-2xl pt-4 pb-4">
                "Helping give sick children what they need most - THEIR FAMILIES!"
            </div>
            <div className="bg-white h-50px w-full grid md:grid-cols-2 sm:grid-cols-1 justify-around items-start p-4">
                <img src="./images/RMcDonaldJul2022_2.jpg" alt="The team at the Ronald McDonald House" />
                <img src="./images/RMcDonaldJul2022_3.jpg" alt="The team at the Ronald McDonald House" />
                <img src="./images/RMcDonaldJul2022_4.jpg" alt="The team at the Ronald McDonald House" />
                <img src="./images/RMcDonaldJul2022_5.jpg" alt="The team at the Ronald McDonald House" />
                {/* <img src="./images/RMcDonaldJul2022_6.jpg" alt="The team at the Ronald McDonald House" />
                <img src="./images/RMcDonaldJul2022_7.jpg" alt="The team at the Ronald McDonald House" /> */}
            </div>
            <div className='text-center text-black justify-center text-xl md:text-5xl lg: text-6xl xl:text-7xl pt-4 pb-4'>
                Thanksgiving Food Drive
            </div>
            <div className="text-center justify-center text-black text-base md:text-lg lg: text-xl xl:text-2xl pt-4 pb-4">
                <a href="https://www.londonfoodbank.ca/" target="blank">Please, visit the London Food Bank <u>website</u> to see how you can help!</a>
            </div>
            <div className="bg-white h-50px w-full grid md:grid-cols-2 sm:grid-cols-1 justify-around items-start p-4">
                <img src="./images/RCSS Food Drive1.jpg" alt="The team at the Food Drive" />
                <img src="./images/RCSS Food Drive2.jpg" alt="The team at the Food Drive" />
            </div>
            <Footer />
        </div>
    )
}

export default Volunteering