import React from 'react';
import './PersonInfo.css';

const StaffInfo = ({ name, title, education, email, StartDate, imageUrl }) => {
  return (
    <div className="person-info-container">
      <div className="headshot-container">
        <img src={imageUrl} alt={name} className="headshot" />
      </div>
      <div className="info">
        <div className='text-left text-black justify-left mx-12 text-lg md:text-xl lg:text-2xl xl:text-4xl'>{name}</div>
        <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>{title}</div>
        <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'><strong>Previous Education:</strong> {education}</div>
        <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'><strong>Email:</strong> {email}</div>
        <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'><strong>Start Date:</strong> {StartDate}</div>
      </div>
    </div>
  );
}

export default StaffInfo;