import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

const Videos = () =>{
    return(
        <div id="everything">
            <NavigationBar />
            <img src="./images/Students in the Deep Learning Room.png" alt="Students in the Deep Learning Room" style={{width:"100%"}} />
            <div className='text-center text-black justify-center text-xl md:text-5xl lg: text-6xl xl:text-7xl pt-4 pb-4'>
                Lung Structure Function Videos
            </div>
            <div className="bg-white h-50px w-full grid md:grid-cols-2 sm:grid-cols-1 justify-around items-start p-4">
                <div className="video-container">
                    <video controls style={{width:"95%"}}>
                        <source src="./files/Video 1 - ROB0042-030.mp4" />
                    </video>
                </div>
                <div className="video-container">
                    <video controls style={{width:"95%"}}>
                        <source src="./files/Video 3 - Fleischner Talk.mp4" />
                    </video>
                </div>
                <div className="video-container">
                    <video controls style={{width:"95%"}}>
                        <source src="./files/Video 6 - ROB0037-014.mp4" />
                    </video>
                </div>
                  
                <div className="video-container">
                    <video controls style={{width:"95%"}}>
                        <source src="./files/Video 4 - Asthma Ventilation and CT.mp4" />
                    </video>
                </div>
                <div className="video-container">
                    <video controls style={{width:"95%"}}>
                        <source src="./files/Video 5 - Asthma with Vessels.mp4" />
                    </video>
                </div>
                <div className="video-container">
                    <video controls autoplay muted style={{width:"95%"}}>
                        <source src="./files/Video 2 - ROB0042-019.mp4" />
                    </video>
                </div> 
                <div className="video-container">
                    <video controls style={{width:"95%"}}>
                        <source src="./files/Video 7 - ROB0042-prebenra.mp4" />
                    </video>
                </div>
                <div className="video-container">
                    <video controls style={{width:"95%"}}>
                        <source src="./files//Video 8 - ROB0042-postbenra.mp4" />
                    </video>
                </div>
                <div className="video-container">
                    <video controls style={{width:"95%"}}>
                        <source src="./files/ROB0037-100_3D_Video.mp4" />
                    </video>
                </div>
                <div className="video-container">
                    <video controls style={{width:"95%"}}>
                        <source src="./files/Lung Video for Lab Meeting.mp4" />
                    </video>
                </div>
            </div>
            <Footer />
        </div>
    )
};

export default Videos