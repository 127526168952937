import React, { useState } from "react";
import "./ourwork.css";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import "./PersonInfo.css"

const projects = [
  // {
  // id: "copdLung",
  // title: "Effect of the Aerobika OPEP device on mucus plugging and airflow obstruction in patients with moderate-to-severe asthma (AEROMUC)",
  // fundedBy: "Trudell Medical",
  // institutions:"McMaster University, Western University, Robarts Research Institute",
  // coInvestigators:"Dr. Sarah Svenningsen, Dr. Cory Yamashita, Dr. Anurag Bhalla, Dr. Parameswaran Nair, Dr. Carmen Venegas",
  // description: [
  //   'Current data suggests that mucus plugs are resistant, at least in part, to first-line asthma therapies. Airway mucus plugs were recently shown to persist over three years, despite treatment with high dose inhaled and oral corticosteroids. Additionally, biologic treatments for the management of asthma, including Benralizumab, Dupilumab and Tezepelumab, have been shown to improve, but not normalize the burden of mucus plugging in asthma. Current literature suggests that mucus clearance might be just as important, if not more important, than luminal eosinophil clearing in some patients with asthma.',
  //   'Airway clearance devices, such as the Aerobika® Oscillating Positive Expiratory Pressure (OPEP) device, have demonstrated efficacy in mobilization of mucus secretions in patients with airways disease and augmented mucus production, including COPD, cystic fibrosis, and bronchiectasis. To our knowledge, airway clearance devices have not been prospectively tested in moderate-to-severe asthma with, or without the addition of biologic therapy. However, it stands to reason that since airway mucus occlusions have been identified as an important feature of asthma pathophysiology, the use of an airway clearance device may help clear such mucus plugs and consequently improve airway function and asthma control.'
  // ] ,
  // deliverables: [
  //   'Change in the CT mucus score between baseline (week 0) and week 16.',
  //   'Change from baseline (week 0) to week 16 in post-bronchodilator MRI ventilation defect percent (VDP), FEV1, Asthma Control Questionnaire-5 (ACQ-5), Asthma Quality of Life Questionnaire (AQLQ), Asthma Control Test (ACT), St. George’s Respiratory Questionnaire (SGRQ), Cough And Sputum Assessment Questionnaire (CASA-Q), fraction of exhaled nitric oxide (FeNO), sputum eosinophil percent, blood eosinophil count, sputum neutrophil percent, inspiratory CT wall area percent (WA%), inspiratory CT lumen area (LA), respiratory system resistance (Rrs) and reactance (Xrs) at 5Hz (Rrs5Hz, Xrs5Hz), Rrs at 19Hz (Rrs 19Hz), the frequency dependence of Rrs (Rrs5-19Hz), and the integrated area of low frequency reactance (AX).'
  // ]
  // },
  {
    id: 'ethaLung',
    title: 'Evaluation of Triple Therapy using Magnetic Resonance Imaging in Asthma (ETHA)',
    fundedBy: 'GSK',
    institutions: 'Western University, Robarts Research Institute',
    coInvestigators: 'Dr. Cory Yamashita, Dr. Lisa Cameron, Dr. Christopher Licskai, Dr. Constance Mackenzie, Dr. David G McCormack, Dr. Narinder S. Paul',
    description: [
      'Achieving and maintaining asthma control is a primary goal of asthma treatment. Despite inhaled corticosteroid (ICS)/long-acting β2-agonist (LABA) therapy, some participants with asthma remain with poorly controlled symptoms. A recent study in asthma participants of triple therapy [ICS/LABA and a long-acting muscarinic antagonist (LAMA)] demonstrated a statistically significant improvement in the forced expiratory volume in 1 second (FEV1) after 24 weeks of treatment as compared to dual therapy. In the same study, the annualised rate of moderate/severe exacerbations also improved, but this improvement was not significantly different as compared to dual therapy.',
      'The main objective of this proof-of-principle, investigator-initiated project is to measure the effect of six weeks of triple therapy with fluticasone furoate, an ICS; umeclidinium (UMEC), a LAMA; and vilanterol (VI), a LABA, delivered in a single daily inhalation via an Ellipta inhaler on <sup>129</sup>Xe magnetic resonance imaging (MRI) ventilation defect percent (VDP), oscillometry and multiple breath nitrogen washout (MBNW) measurements in asthma participants with poor disease control while prescribed low or medium dose ICS/LABA inhaled therapy.'
    ],
    deliverables: [
      '1: To measure the effect of FF/UMEC/VI 200/62.5/25µg QD on biomarkers of pulmonary function and inflammation.',
      '2: To measure the effect of FF/UMEC/VI 200/62.5/25µg on asthma control and quality-of-life.',
      '3: To assess the safety and tolerability of FF/UMEC/VI.',
      '4: To evaluate the relationships between MRI, VDP, and asthma control and quality-of-life.',
      '5: To evaluate the relationships between MRI, VDP, and lung function.',
      '6: To evaluate the relationships between MRI, VDP, airway, and blood eosinophilia.'
    ]
  },
  {
    id: 'vapingLung',
    title: 'Novel Pulmonary Imaging of Lung Structure and Function in Symptomatic and Asymptomatic E-cigarette Smokers',
    fundedBy: 'CIHR',
    institutions: 'Western Unviersity, Robarts Research Institute',
    coInvestigators: 'Dr. Inderdeep Dhaliwal, Dr. Narinder S. Paul, Dr. Alexei Ouriadov, Dr. Karen Bosma, Dr. Constance Mackenzie, Dr. Michael Nicholson',
    description: [
      'Current smokers and vapers as well as health care providers do not have a good understanding about the short- and long-term lung impacts of e-cigarette use. There is little to no longitudinal information about the impact of vaping on former and current combustible cigarette (c-cigarette) smokers, nor is there sufficient information to inform children and teenagers who have never smoked tobacco c-cigarettes but are considering using e-cigarettes for flavours, THC or nicotine inhalation. While severe and serious cases of EVALI (e-cigarette or vaping use-associated lung injury) that lead to hospitalization have been well-characterized, there is little understanding of the prevalence of lung abnormalities in mildly symptomatic and asymptomatic individual users. What we do know is that users of e-cigarettes have reported several negative symptoms involving the nose, mouth, throat and airways. A number of surveys in adolescents have also reported increased risk of respiratory symptoms as well as new diagnoses of asthma and asthma exacerbations that stem from e-cigarette use.',
      'The main objective of this proof-of concept study is to determine if e-cigarette users who did not require hospitalization, nor used c-cigarettes report pulmonary imaging abnormalities (vessels, airways, parenchyma and perfusion), respiratory symptoms or abnormal pulmonary function (oscillometry, multi-breath inert gas washout, spirometry).'
    ],
    deliverables: [
      '1: Measuring the impact of vaping on respiratory-related physical activity, quality of life, and health service utilization.',
      '2: Determining if lung physiologic and imaging biomarkers measured at 12 weeks predict lung health changes at 48 weeks.',
      '3: Determining if lung abnormalities in vapers differ by sex, age, underlying lung and other chronic disease status, smoking, and socioeconimic status.',
      '4: Developing an intervention - specifically a knowledge translation guidance statement for Canadian stakeholders and e-cigarette users on ways to monitor lung health to detect early signs of lung changes.'
    ]
  },
  {
    id: "healthyAgingLung",
    title: "LIVE COVID FREE",
    institutions: "McMaster University, Lakehead University, Toronto Metropolitan University, University of Toronto, Western University, Robarts Research Institute",
    fundedBy: "Ministry of Health and Long-Term Care Ontario",
    coInvestigators:"Dr. Michael Nicholson, Dr. Mitch Albert, Dr. Miranda Kirby, Dr. Alexei Ouriadov, Dr. Giles Santyr, Dr. Sarah Svenningsen",
    description: [
      'Pulmonary COVID-19 viral infection can result in viral pneumonia involving the terminal airways and alveoli; viral re-production damages and destroys alveolar cells, causes whole lung inflammation, failed gas exchange, coagulopathies and respiratory failure. Moreover, in survivors of severe disease there is strong computed tomography (CT) evidence of persistent pulmonary abnormalities and lung damage which may have long-term consequences for COVID-19 survivors and the health-care system, long after the end of the first wave of infection. Sensitive and specific predictors or biomarkers that can evaluate quality-of-life, risk of post-COVID-19 infection exacerbation (including shortness of breath, lung infection) and other long-term outcomes are critically needed. Moreover, to plan healthcare and societal resources for the next wave of COVID-19 infection, it is critical to understand post-infection pulmonary structure-function findings in all Ontarians including those with chronic lung diseases such as asthma and COPD.',
      'Non-invasive pulmonary imaging using hyperpolarized <sup>129</sup>Xe magnetic resonance imaging (MRI) in combination with low dose computed tomography (CT) provides a way to comprehensively detect abnormalities in the pulmonary small airways, vasculature and parenchyma. <sup>129</sup>Xe MRI is a rapid (10s), non-invasive and radiation-free method, and spatially measures the structure and function of the terminal airways where gas exchange happens – the alveoli and acinar ducts. This MRI approach provides a way to simultaneously quantify the regions of the lung that participate in ventilation and perfusion, and those that do not.'
    ] ,
    deliverables: [
      '1: Determine if COVID-19 survivors, who did and did not require hospitalization, develop long-term respiratory impairment at one year, defined as sustained respiratory symptoms, abormal pulmonary function testing or lung CT abnormalities',
      '2: Determine the clinical, structural physiologic and imaging biomarkers within 3 months post COVID-19 infection recovery',
      '3: Determine if COVID-19 induced respiratory impairment and predictors of respiratory impairment differ by sex, age, underlying lung disease or other comorbidities, smoking history, and socioeconomic status',
      '4: Generate and provide rapid evidence-based guidance to healthcare providers, health system planners, and patients regarding monitoring to detect early signs of respiratory impairment post-COVID-19 infection and care required to prevent development of significant lung disease'
    ]
  },
  {
    id: 'tinCanLung',
    title: 'Thoracic Imaging Network of Canada (TIN Can)',
    fundedBy: 'CIHR',
    institutions: 'University of British Columbia, Western University, Robarts Research Institute',
    coInvestigators: 'Dr. James Hogg, Dr. Don Sin, Dr. Harvey Coxson, Dr. John Mayo, Dr. Stephen Lam, Dr. Giles Santyr, Dr. Aaron Fenster, Dr. David G McCormack',
    description: [
      'Currently, COPD clinical care and research depends upon lung function measurements as the gold standard markers of disease progression and severity. Unfortunately, these measurements provide no information about COPD pathological phenotypes and these are furthermore insensitive to important functional and structural changes that occur over time.',
      'Therefore, the key anticipated value of this CIHR team related to integrated interdisciplinary research that develops and validates highly predictive hybrid imaging maps and models of the COPD lung, to improve our fundamental understanding of COPD phenotypes, leading to new intermediate endpoints and biomarkers that will translate to new and better patient treatments, management, and outcomes.',
      'In summary, the value-added of this team approach is related to the translation of research tools and results from COPD tissue ex vivo analysis to in vivo patient imaging studies. In theme A, we identify and quantify ex vivo lung tissue structural measurements from resected tissue from COPD patients and these results will generate "ground truth" to generate a validation platform for MDCT, MRI, OCT, and spectroscopy measurements developed in vivo in the same patients in Themes B and C.'
    ],
    deliverables: [
      '1: Application of micro-imaging of ex vivo tissue to understand the "natural" history of COPD from the airways to the parenchyma;',
      '2: Development of validated, in vivo patient imaging using MRI, CT, OCT, and multi-spectral spectroscopic algorithms for COPD phenotyping and for evaluating outcomes of clinical trials;',
      '3: COPD patient imaging targeted to provide an understanding of the clinical outcomes associated with COPD phenotypes;',
      '4: Translating new knowledge to scientists, clinicians, and to industry to optimally use in vivo imaging for clinical studies and in the development of novel therapeutic drug targets; and,',
      '5: Training the next generation of COPD imaging scientists and clinicians.'
    ]
  },
  {
    id: 'copdLung',
    title: 'Longitudinal COPD Phenotyping Using Hyperpolarized <sup>3</sup>He MRI',
    fundedBy: 'CIHR',
    institutions: 'University of British Columbia, Western University, Robarts Research Institute',
    coInvestigators: 'Dr. Harvey Coxson, Dr. David G McCormack, Dr. Giles Santyr',
    description: [
      'The development of chronic lung disease related to tobacco smoking, the use of biomass fuels and inhalation of environmental toxins, known as Chronic Obstructive Pulmonary Disease (COPD) affects at least one million Canadians and 600 million people worldwide. It is currently the fourth leading cause of death worldwide and continues to grow in prevalence and mortality rates.It is directly responsible for 10,000 deaths and 100,000 hospitalizations each year in Canada, costing over $5 billion in direct and indirect costs. Despite the staggering societal burden of COPD and decades of active research, therapeutic breakthroughs have not occurred, largely because there is: 1) an incomplete understanding of COPD pathogenesis, 2) inadequate patient phenotyping, and, 3) a scarcity of tools that can sensitively and precisely track disease changes. These limitations are critical considerations when new theapies are evaluated in clinical trials because the surrogate endpoints currently in use are poor surrogates for long-term health outcomes -the ultimate target of new treatments. In response to these serious limitations, non-invasive in vivo imaging techniques have been proposed as promising solutions because of their potential to provide new remedial intermediate markers or phenotypes that directly quantify lung pathologic and functional changes. Accordingly, the focus of this research proposal is the quantification and validation of such in vivo COPD phenotypes.', 
      'We think that <sup>3</sup>He MRI provides a complementary and alternative method for evaluating COPD and may be superior to CT because it allows simultaneous visualization of airway and airspace structure and function at high spatial and temporal resolution, without x-ray radiation risk. We hypothesize that imaging measurements can be used to determine the relative contributions of airway obstruction and emphysema in individual patients and furthermore provide a better way to stratify patients based upon underlying pathological changes. The stratification of differential phenotyping of COPD patients based upon different underlying pathologies has the potential to have a profound effect on patient management because the treatments required are likely very different.',
      'The goal of this research is to provide a detailed in vivo understanding of the underlying pathophysiology of COPD, through the identification and validation of in vivo imaging intermediate endpoints.',
      'As a first step, we will develop image analysis tools for the sensitive, specific and precise measurement of both airways and airspace changes, providing for the first time, an in vivo window on these complex underlying COPD pathologies. These tools will provide a way to measure lung pathological and functional changes longitudinally and stratify patients based upon pathologies that are mechanistically related, but very different. Quantitative in vivo phenotypes also provide new COPD intermediate endpoints for interventional and genetic studies, identified by NIH as a critical research gap that needs to be addressed.'
    ],
    deliverables: [
      '1: Identify, quantify and provide histological validation of disease structural and functional phenotypes derived from <sup>3</sup>He MR and CT of subjects with GOLD stage 2 and 3 COPD',
      '2: Determine how CT and <sup>3</sup>He MR imaging phenotypes can be used to stratify COPD subjects',
      '3: Determine the relationship between <sup>3</sup>He MRI and CT measures of COPD, and also with pulmonary function measurements, lung volumes, global health status, and functional capacity'
    ]
  },
  {
    id: 'healthyAgingLung',
    title: 'Imaging the Healthy Aging Lung',
    fundedBy: 'CIHR',
    institutions: 'Western University, Robarts Research Institute, McMaster University',
    coInvestigators: 'Dr. Parameswaran Nair, Dr. Geoff Maksym, Dr. David G McCormack, Dr. Chris Licskai',
    description: [
      'Respiratory changes that accompany aging such as global lung function decreases and regional structural alterations result in increased breathlessness or higher risk of respiratory illness and decreased exercise tolerance, all of which result in decreased quality of life. Despite decades of active research, as well as the staggering and growing societal burden of the effects of diminished respiratory function in the elderly, little is known about how to differentiate "usual aging" representing the effects of aging mixed with the potentially negative lifestyle and environmental factors, from "unusual aging" reflecting not only a lack of apparent disease but physiological performance that is not significantly different from healthy young adults. Moreover, currently there are no tools that can be used to predict those who might be at risk of accelerated aging.',
      'In this regard, we recently were the first to report the unexpected <sup>3</sup>He magnetic resonance imaging (MRI) finding that healthy elderly never-smokers with apparently normal lung function, exhibited lung imaging changes that were not significantly different from age-matched ex-smokers with Global Obstructive Lung Disease (GOLD) stage II chronic obstructive pulmonary disease (COPD). These surprising imaging findings suggested that significant and unexpected lung changes were occurring in healthy elderly never-smokers (with apparently age-normalized lung function), providing important clues about the aging lung and identifying the (previously unrecognized) potential for lung imaging to provide sensitive measurements of aging.',
      'Our central hypothesis is that lung MRI provides in vivo phenotypes or intermediate candidate markers of lung morphology and function that can be used to quantify healthy respiratory aging and to differentiate between "usual" and "unusual" aging lung. We also hypothesize that imaging measurements will show significant associations with established measurements of functional capacity and exercise-induced dyspnea and will respond to bronchodilatory intervention.',
      'A fundamental understanding of healthy aging lung structure and function has the potential to guide the development of novel strategies to improve functional declines, evaluate predisposition to accelerated lung aging and identify environmental and other risk factors. As a first step towards this goal, we are evaluating novel image acquisition and analysis tools already developed in our lab for the sensitive, specific, and precise measurement of lung changes, providing for the first time, an in vivo window on these complex underlying age-related changes in elderly never-smokers.'
    ],
    deliverables: [
      '1: Quantify and compare in vivo lung structural and functional <sup>3</sup>He and <sup>1</sup>H MRI measurements at baseline and three years later and determine the significant relationships between these and pulmonary function measurements, lung volumes, and functional capacity',
      '2: Evaluate MRI measurement response to bronchodilation',
      '3: Test a theoretical micromechanical lung model using longitudinal <sup>3</sup>He MRI',
      '4: Determine how <sup>3</sup>He MRI measurements stratify subjects into different subgroups'
    ]
  },  
  // Add other projects here following the same structure
];

const Project = ({ project, isOpen, onClick }) => {
  return (
    <div>
      <div className="workImg" id={project.id} onClick={onClick}>
        <div className="center">
          <div className='text-center text-white justify-center text-lg md:text-3xl lg: text-4xl xl:text-4xl'>
            <div dangerouslySetInnerHTML={{ __html: project.title }} />
          </div>
          <br></br>
          <div className='text-center text-white justify-left text-base md:text-lg lg:text-xl xl:text-2xl'>
            Funded by: {project.fundedBy}
          </div>
          <div className='text-center text-white justify-left text-base md:text-lg lg:text-xl xl:text-2xl'>
            Institutions: {project.institutions}
          </div>
          <div className='text-center text-white justify-left text-base md:text-lg lg:text-xl xl:text-2xl'>
            Co-Investigators: {project.coInvestigators}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="container main">
          <div className="col">
            <div className="box" style={{ border: 'white', background:'white' }}>
              <div className="text-black">
              {project.description.map((description, index) => (
                  <div className='text-left text-black py-2 justify-left text-md md:text-base lg:text-lg xl:text-xl'
                   key={index} dangerouslySetInnerHTML={{ __html: description }} />
              ))}
              </div>
            </div>
          </div>
          <div className="col" style={{ width: '40%' }}>
            <div className="box">
              <div className="text-black">
              <div className='text-left text-black py-2 justify-left text-md md:text-base lg:text-lg xl:text-xl'>
                Our deliverables for the program and team include:
              </div>
              {project.deliverables.map((deliverable, index) => (
                <div className='text-left text-black py-2 justify-left text-md md:text-base lg:text-lg xl:text-xl'
                 key={index} dangerouslySetInnerHTML={{ __html:deliverable}} />
              ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const OurWork = () => {
  const [openProjectId, setOpenProjectId] = useState(null);

  const handleImageClick = (id) => {
    setOpenProjectId(openProjectId === id ? null : id);
  };

  return (
    <div id="everything">
      <NavigationBar />
      <div className="work-container">
        <div className="col">
          <div className='text-center text-black justify-center py-8 text-xl md:text-2xl lg: text-3xl xl:text-4xl'>
            Our Work
          </div>
          <br></br>
          <div className='px-3'>
          <a href="https://pubmed.ncbi.nlm.nih.gov/?term=parraga+g&sort=date" target="blank">
            <p className="button">Pubmed</p>
          </a>
          <br></br>
          <a href="/covid">
            <p className="button">covid-19 research</p>
          </a>
          <br></br>
          <a href="/talksandposters">
            <p className="button">talks and posters</p>
          </a>
          <br></br>
          <a href="/polarizationfacility">
            <p className="button">polarization facility</p>
          </a>
          <br></br>
          <a href="/ourcode">
            <p className="button">our code</p>
          </a>
          <br></br>
          <a href="/videos">
            <p className="button">videos</p>
          </a>
          <br></br>
          <a href="/volunteering">
            <p className="button">volunteering</p>
          </a>
          <br></br>
          <a href="/poetry">
            <p className="button">poetry</p>
          </a>
          </div>
          <br></br>
          <div className="text-center text-black justify-center text-base md:text-lg lg:text-xl xl:text-2xl">
            Click on our projects below to learn more about them
          </div>
        </div>
        <div className="photo">
          <img src="./images/Group on Stairs.png" alt="team on stairs" className="object-position:right width:100% transform-origin: right" />
        </div>
      </div>
      <div>
      {projects.map((project) => (
        <Project
          key={project.id}
          project={project}
          isOpen={openProjectId === project.id}
          onClick={() => handleImageClick(project.id)}
        />
      ))}
      </div>
      <Footer />
    </div>
  )
};

export default OurWork;