import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; // Import BrowserRouter and Route
import Home from "./Home"; // Import your Home component
import CommunityResources from "./CommunityResources";
import ForTheLab from "./ForTheLab";
import Impact from "./Impact";
import JoinUs from "./JoinUs";
import OurCode from "./OurCode";
import OurTeam from "./OurTeam";
import OurWork from "./OurWork";
import Patients from "./Patients";
import Polarization from "./PolarizationFacility";
import TalksAndPosters from "./Talks_Posters";
import Videos from "./Videos";
import Volunteering from "./Volunteering";
import Poetry from "./poetry";
import Covid from "./covid";

function App() {
  return (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path='/CommunityResources' element={<CommunityResources />} />
      <Route path='/ForTheLab' element={<ForTheLab />} />
      <Route path='/Impact' element={<Impact />} />
      <Route path='/JoinUs' element={<JoinUs />} />
      <Route path='/OurCode' element={<OurCode />} />
      <Route path='/OurTeam' element={<OurTeam />} />
      <Route path='/OurWork' element={<OurWork />} />
      <Route path='/Patients' element={<Patients />} />
      <Route path='/PolarizationFacility' element={<Polarization />} />
      <Route path='/TalksAndPosters' element={<TalksAndPosters />} />
      <Route path='/Videos' element={<Videos />} />
      <Route path='/Volunteering' element={<Volunteering />} />
      <Route path='/poetry' element={<Poetry />} />
      <Route path='/covid' element={<Covid />} />
    </Routes>
  </Router>
  );
}

export default App;