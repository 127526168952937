import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";

const JoinUs = () => {
    return (
        <div id="everything">
            <NavigationBar />
            <img src="./images/White Shirts on Stairs cropped.png" width={`100%`} alt="Staff and Students in the API Lab" />
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
                We seek researchers and trainees who show great promise and an aptitude for research.
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
                A very strong undergraduate transcript from an accredited University is helpful, but in addition
                and importantly, strong letters of recommendation are required from faculty who know you well,
                and have guided your previous research efforts. I will use all of this information, together with
                a personal interview, to admit up to 3 graduate students each year, with the final selection 
                typically occuring in the spring.
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
                Our departments at Western University provide tuition and stipend support for all students in good
                standing throughout their graduate work. I also encourage you to apply for scholarship support from
                the National Science and Engineering Council (NSERC) and Canadian Institutes of Health Research.
            </div>
            <br></br>
            <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
                Typically, new students enter my laboratory and their graduate programs at the beginning of the 
                fall term. The deadline for completion of applications is March 1st, but we often fill our positions
                earlier in the year. We look forward to hearing from you!
            </div>
            <br></br>
            <div className="box" style={{background: 'white'}}>
                <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
                    Click here to see our publications:
                </div>
                <a href="https://pubmed.ncbi.nlm.nih.gov/?term=parraga+g&sort=date" target="blank">
                    <p className = "button">Pubmed</p>
                </a>
            </div>
            <Footer />
        </div>
    ) 
};

export default JoinUs