import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import "./impact.css";

const newsArticles = [
  {
      title: "Part 1: Introduction - Guitar and Musical MRI Concepts",
      date: "Target Audience: early-undergraduate Science/Engineering students",
      link: "https://youtu.be/QrsVWSH3-GY"
  },   
  {
      title: "Part 2: MRI Scanner Parts & Basic Pulse Sequence",
      date: "Target Audience: mid-undergraduate Science/Engineering students",
      link: "https://youtu.be/_LOhtMNmhyc"
  },
  {
      title: "Part 3: MR Image Reconstruction with Fourier Thinking",
      date: "Target Audience: senior-undergraduate/graduate Science/Engineering Students",
      link: "https://youtu.be/OT_TtmRXlV4"
  },
];

const otherResources = [
  {
    title: "Educational Science Games",
    link: "https://www.uscareerinstitute.edu/library/educational-science-games"
  },   
  {
    title: "Canadian Association for Girls in Science",
    link: "https://girlsinscience.ca/"
  },
  {
    title: "A Kids Guide to Health and the Human Body",
    link: "https://www.dentalone-md.com/locations/abingdon/a-kids-guide-to-health-and-the-human-body/"
  },
  {
    title: "Bill Nye the Science Guy",
    link: "http://billnye.com/"
  },
  {
    title: "Canadian Science",
    link: "http://www.science.ca/"
  },
  {
    title: "Nemours Kids Health",
    link: "https://kidshealth.org/en/kids/center/htbw-main-page.html"
  },
  {
    title: "Kids Chemistry 101",
    link: "http://www.ducksters.com/science/chemistry/"
  },
  {
    title: "A Home Guide to Kitchen Science",
    link: "http://www.designerappliances.com/articles/a-home-guide-to-kitchen-science-experiments/"
  },
  {
    title: "Chemistry Lesson Glossary of Terms: Atoms, Compounds, and Ions",
    link: "https://www.goodfellow.com/blog/2117/chemistry-lesson-glossary-of-terms-atoms-compounds-and-ions"
  },
  {
    title: "The Scientific Method: Data and Science for Kids",
    link: "http://www.securedatarecovery.com/resources/the-scientific-method-data-and-science-for-kids"
  },
  {
    title: "Science Experiments for All Around Your Home",
    link: "http://www.homeadvisor.com/article.show.Science-Experiments-for-All-Around-Your-Home.17372.html"
  },
];

const CommunityResources = () => {
  return (
    <div id="everything">
      <NavigationBar />
      <br />
      <div className='text-center text-black justify-center p-4 text-lg md:text-5xl lg: text-6xl xl:text-7xl'>
        Community Resources
      </div>
      <br />
      <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
        Oral and written communication of research and science is a lifelong pursuit for most
        of us who are scientists. As part of our commitment to enhancing the understanding of
        science and engineering in the public, we have implemented an outreach program which provides
        lectures and presentations to junior and senior elementary school students.
      </div> 
      <br></br>
      <div className='text-left text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
        Kids of all ages love science and research and as part of our mandate to foster new
        research projects, programs, and funding, we provide the following links to research and
        and science sites suitable for kids.
      </div>
      <br></br>
      <div className='text-left font-bold text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
        Guitar-based MRI Tutorials by Dr. Jerry Battista
      </div>
      <div className="articles">
        {newsArticles.map((article, index) => (
          <div className="article" key={index}>
            <div className="text-black font-bold p-1 text-md md:text-lg lg:text-xl xl:text-2xl">
              <a href={article.link} target="_blank" rel="noopener noreferrer">{article.title}</a>
            </div>
            <div className="text-black p-1 txt-md md:text-lg lg:text-xl xl:text-2xl">
              {article.date}
            </div>
          </div>
        ))}
      </div>
      <div className='text-left font-bold text-black justify-left mx-12 text-base md:text-lg lg:text-xl xl:text-4xl'>
        Other Resources
      </div>
      <div className="articles">
        {otherResources.map((article, index) => (
          <div className="article" key={index}>
            <div className="text-black font-bold p-1 text-md md:text-lg lg:text-xl xl:text-2xl">
              <a href={article.link} target="_blank" rel="noopener noreferrer">{article.title}</a>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default CommunityResources;
