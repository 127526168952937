import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
import "./impact.css";

const labHandbook = [
    {
        title: "Click here to open and download the lab handbook",
        link: "files/Parraga Lab Handbook Version 12 March 13 2024.pdf"
    },   
];

const MSDS = [
    {
        title: "Bleach",
        link: "files/Clorox Regular Bleach_2020_May_18_CAN_EN.pdf"
    },   
    {
        title: "Carbon Dioxide",
        link: "files/carbon dioxide_2022_June_20_CAN_EN.pdf"
    },
    {
        title: "Carbon Monoxide",
        link: "files/carbon monoxide_2021_October_07_CAN_EN.pdf"
    },
    {
        title: "Compressed Air",
        link: "files/Falcon Dust-Off Compressed Gas Duster_2021_August_20_CAN_EN.pdf"
    },   
    {
        title: "Goo Gone",
        link: "files/Goo Gone_2021_December_10_CAN_EN.pdf"
    },
    {
        title: "Hand Sanitizer",
        link: "files/PURELL Instant Hand Sanitizer_2023_March_10_CAN_EN.pdf"
    },   
    {
        title: "Helium",
        link: "files/helium_2022_June_20_CAN_EN.pdf"
    },
    {
        title: "Helium-4",
        link: "files/helium-3_2023_March_01_CAN_EN.pdf"
    },
    {
        title: "Neon",
        link: "files/neon_2022_June_20_CAN_EN.pdf"
    },   
    {
        title: "Nitrogen",
        link: "files/nitrogen_2022_June_20_CAN_EN.pdf"
    },
    {
        title: "Oxygen",
        link: "files/oxygen_2022_June_20_CAN_EN.pdf"
    },
    {
        title: "Sunlight Dishwashing Liquid",
        link: "files/Sunlight Dishwashing Liquid_2022_December_23_CAN_EN.pdf"
    },
    {
        title: "Ultrasound Transducer Disinfectant",
        link: "files/Virox Accel TB (CAN)_2020_September_04_CAN_EN.pdf"
    },   
    {
        title: "Ultrasound Transmission Gel",
        link: "files/Aquasonic 100 Ultrasound Transmission Gel_2022_December_23_CAN_EN.pdf"
    },
    {
        title: "Lysol All-Purpose Cleaner",
        link: "files/Reckitt Benckiser Lysol Brand All Purpose Cleaner __2021_October_07_CAN_EN.pdf"
    },
    
  ];

const ForTheLab = () => {
    return (
        <div id='everything'>
            <NavigationBar />
            <div className='text-center text-black justify-center p-4 text-lg md:text-5xl lg: text-6xl xl:text-7xl'>
                Staff and Trainee Handbook
            </div>
            <div className="articles">
                {labHandbook.map((article, index) => (
                <div className="passwordarticle" key={index}>
                    <div className="text-black font-bold p-1 text-md md:text-lg lg:text-xl xl:text-2xl">
                        <a href={article.link} target="_blank" rel="noopener noreferrer">{article.title}</a>
                    </div>
                </div>
                ))}
            </div>
            <div className='text-center text-black justify-center p-4 text-lg md:text-5xl lg: text-6xl xl:text-7xl'>
                Material Safety Data Sheets
            </div>
            <div className="articles">
                {MSDS.map((article, index) => (
                <div className="passwordarticle" key={index}>
                    <div className="text-black font-bold p-1 text-md md:text-lg lg:text-xl xl:text-2xl">
                        <a href={article.link} target="_blank" rel="noopener noreferrer">{article.title}</a>
                    </div>
                </div>
                ))}
            </div>
            <Footer />
        </div>
    ) 
};

export default ForTheLab