// src/components/ImageTable.js
import React, { useState } from 'react';
import './ImageTable.css';

const images = [
  {
    src: './images/Covid Collage/Alex_going_inside_the_scanner.jpg',
    title: 'Image 2',
    description: 'Alex going inside the scanner.',
  },
  {
    src: './images/Covid Collage/Filling_the_helium-3_bag.jpg',
    title: 'Image 9',
    description: 'Filling the helium-3 bag.',
  },
  {
    src: './images/Covid Collage/Alex_seeing_images_of_his_lungs.jpg',
    title: 'Image 5',
    description: 'Alex seeing images of his lungs.',
  },
  {
    src: './images/Covid Collage/These_cookies_look_way_too_good!.jpg',
    title: 'Image 16',
    description: 'These cookies look way too good!',
  }
];

const TopImageTable = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div className="image-table">
      <div className="table">
        {images.map((image, index) => (
          <div key={index} className="cell" onClick={() => handleImageClick(image)}>
            <img src={image.src} alt={image.title} />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className='overlay'>
          <div className="popup">
            <div className="popup-content">
              <span className="close" onClick={handleClose}>&times;</span>
              <img src={selectedImage.src} alt={selectedImage.title} />
              <div className='text-black'>{selectedImage.description}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopImageTable;
