import React from "react";
import NavigationBar from "./components/NavigationBar";
import Footer from "./components/Footer";
// import PDFViewer from "./PDFViewer";

const TalksAndPosters = () => {
    return (
        <div id="everything">
            <NavigationBar />
            <img src="./images/Sam T With Poster.png" alt="Student Sam Tcherner Presenting his work" style={{width:"100%"}}/>
            <br></br>
            <div  className='text-center text-black justify-center text-xl md:text-5xl lg: text-6xl xl:text-7xl'>
                ATS 2024 Posters!
            </div>
            <br></br>
            <div>
                <div className="bg-white h-50px w-full grid grid-cols-1 justify-around items-start p-6">
                <div className="text-left text-black justify-left mx-12 text-lg md:text-xl lg:text-2xl xl:text-3xl">
                    Maksym Sharma
                </div>
                <img src="./files/MSharma ATS Asthma DPh April 29 2024 FINAL.png" alt="Maksym Sharma Gas Exchange Poster" />
                
                {/* <PDFViewer name="Maksym Sharma" path="./files/MSharma ATS Asthma DPh April 29 2024 FINAL.pdf" /> */}
                <br></br>
                <img src="./files/MSharma ATS Asthma Persistent Response April 28 2024 FINAL.png" alt="Maksym Sharma Asthma" />
                {/* <PDFViewer name="Maksym Sharma" path="./files/MSharma ATS Asthma Persistent Response April 28 2024 FINAL.pdf" /> */}
                <br></br>
                <div className="text-left text-black justify-left mx-12 text-lg md:text-xl lg:text-2xl xl:text-3xl">
                    Harkiran Kooner
                </div>
                <img src="./files/Kooner ATS Mucus Poster May 8 2024 FINAL.png" alt="Harkiran Kooner Mucus" />
                <br></br>
                <img src="./files/Kooner ATS Vessel Poster May 38 2024 FINAL.png" alt="Harkiran Kooner Vessels" />
                <br></br>
                {/* <PDFViewer name="Harkiran Kooner" path="./files/Kooner ATS Mucus Poster May 8 2024 FINAL.pdf" />
                <PDFViewer name="Harkiran Kooner" path="./files/Kooner ATS Vessel Poster May 38 2024 FINAL.pdf" /> */}
                <div className="text-left text-black justify-left mx-12 text-lg md:text-xl lg:text-2xl xl:text-3xl">
                    Ali Mozaffaripour
                </div>
                <img src="./files/AliM ATS Poster May 10 2024 9AM FINAL LZ.png" alt="Ali Mozaffaripour ATS" />
                <br></br>
                {/* <PDFViewer name="Ali Mozaffaripour" path="./files/AliM ATS Poster May 10 2024 9AM FINAL LZ.pdf" /> */}
                <div className="text-left text-black justify-left mx-12 text-lg md:text-xl lg:text-2xl xl:text-3xl">
                    Sam Tcherner
                </div>
                <img src="./files/STcherner FINAL ATS Poster Apr 26 2024 11AM.png" alt="Sam Tcherner ATS" />
                <br></br>
                {/* <PDFViewer name="Sam Tcherner" path="./files/STcherner FINAL ATS Poster Apr 26 2024 11AM.pdf" /> */}
                <div className="text-left text-black justify-left mx-12 text-lg md:text-xl lg:text-2xl xl:text-3xl">
                    Marrissa McIntosh
                </div>
                <img src="./files/McIntosh ATS Poster May 6 2024 930AM.png" alt="Marrissa McIntosh ATS" />
                <br></br>
                {/* <PDFViewer name="Marrissa McIntosh" path="./files/McIntosh ATS Poster May 6 2024 930AM.pdf" /> */}
            </div>
            <br></br>
            </div>
            <div  className='text-center text-black justify-center text-xl md:text-5xl lg: text-6xl xl:text-7xl'>
                ISMRM 2024 Posters!
            </div>
            <br></br>
            <div> 
                <div className="text-left text-black justify-left mx-12 text-lg md:text-xl lg:text-2xl xl:text-3xl">
                    Harkiran Kooner
                </div>
                <div className="bg-white h-50px w-full grid gap-2 md:grid-cols-2 sm:grid-cols-1 justify-around items-start p-4">
                    <img src="./files/Kooner ISMRM 2024 Slides April 24 2024 FINAL_Page_01.png" alt="ISMRM Slide 1" />
                    <img src="./files/Kooner ISMRM 2024 Slides April 24 2024 FINAL_Page_02.png" alt="ISMRM Slide 2" />
                    <img src="./files/Kooner ISMRM 2024 Slides April 24 2024 FINAL_Page_03.png" alt="ISMRM Slide 3" />
                    <img src="./files/Kooner ISMRM 2024 Slides April 24 2024 FINAL_Page_04.png" alt="ISMRM Slide 4" />
                    <img src="./files/Kooner ISMRM 2024 Slides April 24 2024 FINAL_Page_05.png" alt="ISMRM Slide 5" />
                    <img src="./files/Kooner ISMRM 2024 Slides April 24 2024 FINAL_Page_06.png" alt="ISMRM Slide 6" />
                    <img src="./files/Kooner ISMRM 2024 Slides April 24 2024 FINAL_Page_07.png" alt="ISMRM Slide 7" />
                    <img src="./files/Kooner ISMRM 2024 Slides April 24 2024 FINAL_Page_08.png" alt="ISMRM Slide 8" />
                    <img src="./files/Kooner ISMRM 2024 Slides April 24 2024 FINAL_Page_09.png" alt="ISMRM Slide 9" />
                    <img src="./files/Kooner ISMRM 2024 Slides April 24 2024 FINAL_Page_10.png" alt="ISMRM Slide 10" />
                </div>
                <br></br>
                {/* <PDFViewer name="Harkiran Kooner" path="./files/Kooner ISMRM 2024 Slides April 24 2024 FINAL.pdf" /> */}
                <div className="text-left text-black justify-left mx-12 text-lg md:text-xl lg:text-2xl xl:text-3xl">
                    Ali Mozaffaripour
                </div>
                <div className="bg-white h-50px w-full grid gap-2 md:grid-cols-2 sm:grid-cols-1 justify-around items-start p-4">
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_01.png" alt="ISMRM Slide 1" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_02.png" alt="ISMRM Slide 2" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_03.png" alt="ISMRM Slide 3" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_04.png" alt="ISMRM Slide 4" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_05.png" alt="ISMRM Slide 5" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_06.png" alt="ISMRM Slide 6" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_07.png" alt="ISMRM Slide 7" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_08.png" alt="ISMRM Slide 8" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_09.png" alt="ISMRM Slide 9" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_10.png" alt="ISMRM Slide 10" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_11.png" alt="ISMRM Slide 11" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_12.png" alt="ISMRM Slide 12" />
                    <img src="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED_Page_13.png" alt="ISMRM Slide 13" />
                </div>
                <br></br>
                {/* <PDFViewer name="Ali Mozaffaripour" path="./files/AliM ISMRM Digital Poster April 28 2024 5PM FINAL NOT RECORDED.pdf" /> */}
                <div className="text-left text-black justify-left mx-12 text-lg md:text-xl lg:text-2xl xl:text-3xl">
                    Maksym Sharma
                </div>
                <div className="bg-white h-50px w-full grid gap-2 md:grid-cols-2 sm:grid-cols-1 justify-around items-start p-4">
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_01.png" alt="ISMRM Slide 1" />
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_02.png" alt="ISMRM Slide 2" />
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_03.png" alt="ISMRM Slide 3" />
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_04.png" alt="ISMRM Slide 4" />
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_05.png" alt="ISMRM Slide 5" />
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_06.png" alt="ISMRM Slide 6" />
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_07.png" alt="ISMRM Slide 7" />
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_08.png" alt="ISMRM Slide 8" />
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_09.png" alt="ISMRM Slide 9" />
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_10.png" alt="ISMRM Slide 10" />
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_11.png" alt="ISMRM Slide 11" />
                    <img src="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL_Page_12.png" alt="ISMRM Slide 12" />
                </div>
                {/* <PDFViewer name="Maksym Sharma" path="./files/MSharma ISMRM Digital Poster April 25 2024 FINAL.pdf" /> */}
            </div>
            <br></br>
            <Footer />
        </div>
    ) 
};

export default TalksAndPosters