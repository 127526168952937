// src/components/ImageTable.js
import React, { useState } from 'react';
import './ImageTable.css';

const images = [
  {
    src: './images/Covid Collage/Alex_getting_setup_in_the_scanner.jpg',
    title: 'Image 1',
    description: 'Alex getting set up in the scanner.',
  },
  {
    src: './images/Covid Collage/Angela_made_an_APILab_cookie_tribute!.jpg',
    title: 'Image 13',
    description: 'Angela made an APILab cookie tribute!',
  },
  {
    src: './images/Covid Collage/Alex_looking_at_images_of_his_lungs.jpg',
    title: 'Image 3',
    description: 'Alex looking at images of his lungs.',
  },
  {
    src: './images/Covid Collage/Pouring_liquid_nitrogen_into_the_polarizer.jpg',
    title: 'Image 11',
    description: 'Pouring liquid nitrogen into the polarizer.',
  },
  {
    src: './images/Covid Collage/Get_your_COVID-19_vaccine!.jpg',
    title: 'Image 15',
    description: 'Get your COVID-19 vaccine!',
  },
  {
    src: './images/Covid Collage/Getting_the_first_images.jpg',
    title: 'Image 10',
    description: 'Getting the first images.',
  },
  {
    src: './images/Covid Collage/Removing_liquid_nitrogen_from_the_polarizer.jpg',
    title: 'Image 12',
    description: 'Removing liquid nitrogen from the polarizer.',
  },
  {
    src: './images/Covid Collage/Alex_looking_at_the_images_after_his_scans.jpg',
    title: 'Image 4',
    description: 'Alex looking at the images after his scans.',
  },
  {
    src: './images/Covid Collage/Dave_cleaning_the_scanner.jpg',
    title: 'Image 7',
    description: 'Dave cleaning the scanner.',
  },
  {
    src: './images/Covid Collage/Alex_wearing_the_coil.jpg',
    title: 'Image 6',
    description: 'Alex wearing the coil.',
  },
  {
    src: './images/Covid Collage/Bringing_us_one_step_closer_to_a_hug!.jpg',
    title: 'Image 14',
    description: 'Bringing us one step closer to a hug!',
  },
  {
    src: './images/Covid Collage/Dave_starting_the_scanner.jpg',
    title: 'Image 8',
    description: 'Dave starting the scanner.',
  }
];

const ImageTable = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };

  const handleClose = () => {
    setSelectedImage(null);
  };

  return (
    <div className="image-table">
      <div className="table">
        {images.map((image, index) => (
          <div key={index} className="cell" onClick={() => handleImageClick(image)}>
            <img src={image.src} alt={image.title} />
          </div>
        ))}
      </div>
      {selectedImage && (
        <div className='overlay'>
          <div className="popup">
            <div className="popup-content">
              <span className="close" onClick={handleClose}>&times;</span>
              <img src={selectedImage.src} alt={selectedImage.title} />
              <div className='text-black'>{selectedImage.description}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageTable;
