import React from "react";
import "./globals.css";
import NavigationBar from "./components/NavigationBar.js";
import Footer from "./components/Footer.js";
import schulichLogo from './images/Schulich_horizontal_CMYK.png';
import robartsLogo from './images/Robarts_horizontal_CMYK.png';
import westernLogo from './images/Western_Horizontal.png';
import lungBW from './images/lung_bw.png';

const Home = () => {
  return (
    <div className="App">
      <NavigationBar />
      <div id= "everything">
      <img src={lungBW}  alt="Welcome to the Advanced Pulmonary Imaging Laboratory (API Lab)" style={{width: "100%"}} />
      <div className = "bg-white h-50px w-full flex md:flex-row flex-col justify-around items-start p-4" style={{display: 'flex'}}>
          <div className="text-left text-black justify-left mx-12 text-lg md:text-xl lg:text-2xl xl:text-3xl"> 
            We are fascinated by the interactions of lung growth and development with
            lung aging and disease. The lungs are central to homeostasis and to life; 
            without optimal lung structure and function, all other physiological systems in
            humans are compromised and eventually fail. Paradoxically, lung abnormalities
            are expressed "silently" until disease mechanisms are well-established or
            irreversible because fundamentally, the respiratory system is over-engineered
            for day to day tasks. This makes detection and a deep understanding of lung
            disease and its mechanisms extremely challenging.
          </div>
          <div className = "row">
            <img src={schulichLogo} alt="Schulich Logo" className="h-auto w-80%" />
            <br></br>
            <img src = {robartsLogo} alt = "Robarts Logo" className="h-auto w-80%" />
            <br></br>
            <img src = {westernLogo} alt = "Western Logo" className="h-auto w-80%" />
          </div>
      </div> 
      <img src="./images/treatment.png"  alt="Changing the way chronic lung diseases are understood and treated" style={{width: "100%"}}/>
      <div className = "container main">
        <div className = "row">
          <div className="col">
            <div className="box">
            <div className="text-left text-black justify-left m-2 text-base md:text-lg lg:text-xl xl:text-2xl">
              A variety of biophysical techniques are used in the Parraga laboratory, 
              with a major emphasis on high resolution magnetic resonance imaging measurements made 
              in vivo in children and adults, and exploring their relationship with
              lung physiology. Ongoing projects are focused on answering fundamental questions about lung health.
            </div>
            <img src="./images/mk1-900wide.png" width={`100%`} alt="our work" />
            <a href="/ourwork">
              <p className = "button">Research and Technology</p>
            </a>
            </div>
          </div>
          <div className = "col">
            <div className = "box">
              <div className="text-left text-black justify-left m-2 text-base md:text-lg lg:text-xl xl:text-2xl">
                Precision MRI Initiative at Western
              </div>
              <img src="./images/Precision MRI Logo Clear.png" width = {`100%`} alt = "MRI Initiative"/>
              <a href="./files/PrecisionInitiative.pdf" target="blank">
                <p className="button">Learn More</p>
              </a>
            </div>
            <div className="box">
            <div className="text-left text-black justify-left m-2 text-base md:text-lg lg:text-xl xl:text-2xl">
              If you have a condition such as asthma, COPD, cystic fibrosis, lung cancer or post radiation lung injury,
              if you are greater than 65 years of age without any lung disease, or you vape, we are currently performing 
              research studies you might be interested in.
            </div>
            <a href="/patients">
              <p className="button">Join a Study</p>
            </a>
          </div>
          </div>
        </div>
      </div>
      <div className="container-home">
        <div className="col">
          <img src="./images/20210601_153013163_iOS.png" alt="students placing a participant in the MRI"/> 
        </div>  
        <div className='col'>
          <img src="./images/20210601_154206890_iOS.png" alt="a participant viewing their images" />   
        </div>
      </div> 
      <div className="container main">
        <div className="box" style={{border: "white", background:"white"}}>
        <div className="parragaPortrait">
          <div className = "container">
            <div className = "col">
              <span></span>
              <div className="text-left text-black justify-left text-lg md:text-xl lg:text-2xl xl:text-3xl">
                Grace Parraga, PhD FCAHS</div>
                <br></br>
              <div className="text-left text-black justify-left text-base md:text-lg lg:text-xl xl:text-2xl"> 
              Tier 1 Canada Research Chair
              </div>
              <div className="text-left text-black justify-left text-base md:text-lg lg:text-xl xl:text-2xl"> 
              Lung Imaging to Transform Outcomes
              </div>
              <hr 
                style={{
                  background: 'black',
                  color: 'black',
                  borderColor: 'black',
                  height: '2px',
                }}/>
              <div className="text-left text-black justify-left text-base md:text-lg lg:text-xl xl:text-2xl">
                Professor and Scientist, Robarts Research Institute
              </div>           
              <div className="text-left text-black justify-left text-base md:text-lg lg:text-xl xl:text-2xl">
                Department of Medical Biophysics</div>
              <div className="text-left text-black justify-left text-base md:text-lg lg:text-xl xl:text-2xl"> 
              Departments of Oncology, Medicine, School of Biomedical Engineering</div>
              <div className="text-left text-black justify-left text-base md:text-lg lg:text-xl xl:text-2xl"> 
              Western University</div>
            </div>
            <div className = "col" >
              <img src="./images/Grace_Parraga_WEBRESOLUTION_15.jpg" width={`90%`} alt="Grace Parraga" />
            </div>
          </div>
        </div>
        </div>
        <div>
          <div className="box" style = {{border:"white", background: "white"}}>
          <a href="/ourteam">
            <p className="button">View Team Members</p>
          </a>
          </div>
        </div>
      </div>  
    </div>
    <Footer />
    </div>
  );
}

export default Home;