import React, { useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import "./Footer.css";

Modal.setAppElement('#root');

function Footer() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState('');
  const correctPassword = 'HyPoL129Xe'; // Replace this with your desired password
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === correctPassword) {
      handleCloseModal();
      navigate('/ForTheLab');
    } else {
      alert('Incorrect password');
    }
  };

  return (
    <footer style={footerStyle} className='flex-wrap'>
      <div style={leftColumnStyle}>
        <div className='font-bold text-base md:text-base lg:text-lg xl:text-lg' >
          Mailing Address
        </div>
        <div className='text-base md:text-base lg:text-lg xl:text-lg'>
          Room 2232 <br></br>
          Robarts Research Institute <br></br>
          Western University <br></br>
          1151 Richmond Street North <br></br>
          London, Ontario, Canada <br></br>
          N6A 5B7
        </div>
      </div>
      <div style={centerColumnStyle}>
        <div className='font-bold text-base md:text-base lg:text-lg xl:text-lg'>
          Email
        </div>
        <div className='text-base md:text-base lg:text-lg xl:text-lg'>
          gparraga@uwo.ca <br></br>
          lungimaging@uwo.ca <br></br>
          <strong>Phone</strong> <br></br>
          (519) 931-5265<br></br>
          <strong>Fax</strong><br></br>
          (519) 931-5238
        </div>
      </div>
      <div style={rightColumnStyle}>
        <div className='font-bold text-base md:text-base lg:text-lg xl:text-lg'>
          Resources
        </div>
        <br></br>
        <p className="footer-button" onClick={handleOpenModal}>For the Lab</p>
        <br></br>
        <a href="/CommunityResources">
          <p className="footer-button">For Our Community</p>
        </a>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Sign In"
        className="Modal"
        overlayClassName="Overlay"
      >
        <form onSubmit={handleSubmit}>
          <h2>Password Protected Page</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <button type="submit" className="modal-button">Submit</button>
          <button type="button" className="modal-button" onClick={handleCloseModal}>Close</button>
        </form>
      </Modal>
    </footer>
  );
}

const footerStyle = {
  backgroundColor: ' #492b7e',
  color: '#fff',
  padding: '20px',
  display: 'flex',
  justifyContent: 'space-between',
  textAlign: 'left'
};

const leftColumnStyle = {
  flex: '1'
};

const centerColumnStyle = {
  flex: '1',
  textAlign: 'left',
  flexDirection: 'column'
};

const rightColumnStyle = {
  flex: '1',
  textAlign: 'left',
  flexDirection: 'column'
};

export default Footer;