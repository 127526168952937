import React from 'react';
import './NavigationBar.css'; 

const NavigationBar = () => {
  return (
    <div className="navbar">
      <div className="text-sm md:text-base lg:text-lg xl:text-lg">
      <ul>
        <li className="right"><a href ="/">Home</a></li>
        <li className="right dropdown">
          <a href="/OurWork">Our Work</a>
          <div className="dropdown-content">
            <a href='https://pubmed.ncbi.nlm.nih.gov/?term=parraga+g&sort=date' target='blank'>PubMed</a>
            <a href="/covid">COVID-19 Research</a>
            <a href="/talksandposters">Talks and Posters</a>
            <a href="/OurCode">Our Code</a>
            <a href="/PolarizationFacility">Polarization Facility</a>
            <a href='/videos'>Lung Videos</a>
          </div>
        </li>
        <li className="right"><a href="/OurTeam">Our Team</a></li>
        <li className="right"><a href="/Impact">Impact</a></li>
        <li className="right"><a href="/Patients">Patients</a></li>
        <li className="right"><a href="/JoinUs">Join Us</a></li>
        </ul>
      </div>
    </div>
  );
};

export default NavigationBar;